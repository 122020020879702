<template>
  <v-skeleton-loader
    :loading="loadingTable"
    :transition="'fade-transition'"
    height="500"
    type="table"
  >
    <v-data-table
      :headers="getHeaders(typesNotHeader)"
      :items="dataTable"
      :items-per-page="dataTable.length"
      hide-default-footer
      dense
    >

      <template slot="body.prepend">
        <tr>
          <th :colspan="getHeaders(typesNotHeader).length" v-if="typeGecom >= 2 && typeGecom < 4">Supervisor: {{ supervisor.entity_cod ? `${supervisor.entity_cod} - ${supervisor.entity_name}` : ''}}</th>
        </tr>
        <tr>
          <th :colspan="getHeaders(typesNotHeader).length" v-if="typeGecom >= 3 && typeGecom < 4">Promotor: {{ promotor.entity_cod ? `${promotor.entity_cod} - ${promotor.entity_name}` : ''}}</th>
        </tr>
      </template>

      <!-- <template v-slot:item.name="{ item }">
          {{ formatName(item)}}
      </template> -->

      <template v-slot:item.entity_cod="{ item }">
        <template>
          <span class="text-truncate text-name">
            {{ item.entity_cod }} - {{ item.entity_name }}
          </span>
        </template>
      </template>

      <template v-slot:item.deve_ant="{ item }">
        <template>
          {{ item.deve_ant | currencyNoSymbol }}
        </template>
      </template>
      <template v-slot:item.proposta="{ item }">
        <v-btn
          style="min-width: 100px;"
          elevation="1"
          color="#FFF"
          plain
          raised
          small
          tile
          @click="openProposta(item)" v-if="typeGecom === 4"
          :height="20"
        >
          {{ item.proposta | currencyNoSymbol }}
        </v-btn>

        <template v-else>
          {{ item.proposta | currencyNoSymbol }}
        </template>
      </template>

      <template v-slot:item.comissao="{ item }">
        {{ item.comissao | currencyNoSymbol }}
      </template>

      <template v-slot:item.liquido="{ item }">
        {{ item.liquido | currencyNoSymbol }}
      </template>

      <template v-slot:item.lp="{ item }">
        {{ item.lp | currencyNoSymbol }}
      </template>

      <template v-slot:item.premio="{ item }">
        {{ item.premio | currencyNoSymbol }}
      </template>

      <template v-slot:item.premios_altos="{ item }">
        {{ item.premios_altos | currencyNoSymbol }}
      </template>

      <template v-slot:item.caixa="{ item }">
          {{ item.caixa | currencyNoSymbol }}
      </template>

      <template v-slot:item.pagto="{ item }">
        {{ item.pagto | currencyNoSymbol }}
      </template>

      <template v-slot:item.deve_atual="{ item }">
        {{ item.deve_atual | currencyNoSymbol }}
      </template>
      
      <template v-slot:item.dt_ultimo_contato="{ item }">
        {{ item.dt_ultimo_contato | datas('DD/MM/YYYY') }}
      </template>

      <template v-slot:item.limite_venda="{ item }">
        {{ item.limite_venda | currencyNoSymbol }}
      </template>

      <template slot="body.append">
        <tr>
          <th>Total</th>
          <th class="text-end">{{ totaisGerais ? totaisGerais.deve_ant : '0,00' | currencyNoSymbol }}</th>
          <th class="text-end">{{ totaisGerais ? totaisGerais.proposta : '0,00' | currencyNoSymbol }}</th>
          <th class="text-end">{{ totaisGerais ? totaisGerais.comissao : '0,00' | currencyNoSymbol }}</th>
          <th class="text-end">{{ totaisGerais ? totaisGerais.liquido : '0,00' | currencyNoSymbol }}</th>
          <th class="text-end">{{ totaisGerais ? totaisGerais.premio : '0,00' | currencyNoSymbol }}</th>
          <th class="text-end">{{ totaisGerais ? totaisGerais.premios_altos : '0,00' | currencyNoSymbol }}</th>
          <th class="text-end">{{ totaisGerais ? totaisGerais.lp : '0,00' | currencyNoSymbol }}</th>
          <th class="text-end">{{ totaisGerais ? totaisGerais.pagto : '0,00' | currencyNoSymbol }}</th>
          <th class="text-end">{{ totaisGerais ? totaisGerais.caixa : '0,00' | currencyNoSymbol }}</th>
          <th class="text-end">{{ totaisGerais ? totaisGerais.deve_atual : '0,00' | currencyNoSymbol }}</th>
          <th></th>
        </tr>

        <tr>
          <th colspan="13">
            Agências:
            <span class="font-weight-regular"> Ativos:</span> {{ totaisGerais ? totaisGerais.ag_ativas : 0 }}
            <span class="ml-3 font-weight-regular"> com Proposta:</span> {{ totaisGerais ? totaisGerais.com_proposta : 0 }}
            <span class="ml-3 font-weight-regular"> sem Proposta:</span> {{ totaisGerais ? totaisGerais.sem_proposta : 0 }}
          </th>
        </tr>
      </template>
    </v-data-table>
  </v-skeleton-loader>
</template>

<script>
export default {
  name: 'TabelaRelatorioGecom',
  props: {
    totaisGerais: {
      type: Object,
      default: () => {},
      required: true
    },
    dataTable: {
      type: Array,
      default: () => {},
      required: true
    },

    supervisor: {
      type: Object,
      default: () => {}
    },

    promotor: {
      type: Object,
      default: () => {}
    },

    loadingTable: {
      type: Boolean,
      default: false
    },

    typesNotHeader: {
      type: Array,
      default: () => []
    },

    typeGecom: {
      type: Number,
      default: null
    }
  },
  data: () => ({
  }),

  computed: {
    headersData () {
      return [
        { align: 'start', width: 100, class: 'table-header', cellClass: 'text-truncate', text: this.getTextHeaderTypeGecom(this.typeGecom), value: 'entity_cod' },
        { align: 'end', class: 'table-header', text: 'Deve Ant.', value: 'deve_ant' },
        { align: 'end', class: 'table-header', text: 'Proposta', value: 'proposta' },
        { align: 'end', class: 'table-header', text: 'Comissão', value: 'comissao' },
        { align: 'end', class: 'table-header', text: 'Líquido', value: 'liquido' },
        { align: 'end', class: 'table-header', text: 'Prêmios', value: 'premio' },
        { align: 'end', class: 'table-header', text: 'Prêmios Altos', value: 'premios_altos' },
        { align: 'end', class: 'table-header', text: 'LP', value: 'lp' }, 
        // { align: 'end', class: 'table-header', text: 'L.P.', value: 'lp' },
        { align: 'end', class: 'table-header', text: 'PGTO', value: 'pagto' },
        { align: 'end', class: 'table-header', text: 'Caixa', value: 'caixa' },
        // { align: 'end', class: 'table-header', text: 'Lim. Venda', value: 'liquido' },
        { align: 'end', class: 'table-header', text: 'Deve Autal', value: 'deve_atual' },
        { align: 'center', class: 'table-header', text: 'Últ. Dia Trab.', value: 'dt_ultimo_contato' },
        // { align: 'start', class: 'table-header', text: 'Últ. Dia Trab.', value: 'lastDate' }
      ]
    }
  },

  methods: {
    formatName (item) {
      return item.json_data ? `(${item.json_data.cod}) ${item.json_data.name}` : item.name
    },

    getHeaders (typesNotHeader) {
      return this.headersData.filter(item => {
        return !typesNotHeader.includes(item.value)
      })
    },

    openProposta(val) {
      this.$emit('openProposta', val)
    },

    getTextHeaderTypeGecom (typeGecom) {
      switch (typeGecom) {
        case null:
          return 'Supervisor'
        case 2:
          return 'Vendedor'
        case 3:
        case 4:
          return 'Revendedor'
        default:
          return 'Revendedor'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .text-name {
    width: 150px!important;
    display: inline-block;
    line-height: 0.9;
  }

  .cadastro-usuario >>> .v-data-table__wrapper table {
    min-width: auto;
  }
</style>